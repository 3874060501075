import { computed, Ref } from '@nuxtjs/composition-api';
import { getGallery as getProductGallery } from '~/modules/catalog/product/getters/productGetters';
import { useImage } from '~/composables';
import type { Product } from '~/modules/catalog/product/types';
import type { UseProductGalleryInterface } from '~/modules/catalog/product/composables/useProductGallery/useProductGallery';
import type { VideoContentDataInterface } from '~/modules/GraphQL/types';

/**
 * The `useProductGallery()` composable allows building product's gallery data structure.
 *
 * See the {@link UseProductGalleryInterface} page for more information.
 */
export function useProductGallery(product: Ref<Product>, imgPlaceholder = ''): UseProductGalleryInterface {
  const { getMagentoImage, imageSizes } = useImage();
  const productGallery = computed(() => getProductGallery(product.value).map((img) => {
    const image: {
      tiny: {
        url: string
      },
      small: {
        url: string
      },
      medium: {
        url: string
      },
      large: {
        url: string
      },
      mega: {
        url: string
      },
      alt: string,
      placeholder: string,
      media_type: string,
      video_content_data?: VideoContentDataInterface
    } = {
      tiny: { url: getMagentoImage(img.tiny) },
      small: { url: getMagentoImage(img.small) },
      medium: { url: getMagentoImage(img.medium) },
      large: { url: getMagentoImage(img.large) },
      mega: { url: getMagentoImage(img.mega) },
      alt: product.value.name,
      placeholder: imgPlaceholder,
      media_type: img.media_type,
    }
    if (img.media_type === "external-video") {
      image.video_content_data = img.video_content_data;
    }
    return image;
  }));

  return {
    productGallery,
    imageSizes,
  };
}

export default useProductGallery;
export * from './useProductGallery';
